"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const Units_1 = require("@/common/Units");
const utils_1 = require("@/common/utils/utils");
exports.default = vue_1.default.extend({
    name: 'SelectProductionToLose',
    props: {
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    data() {
        return {
            units: Object.assign({}, Units_1.Units.EMPTY),
            warning: undefined,
        };
    },
    methods: {
        canDeductMegaCredits() {
            return this.playerinput.payProduction.units.megacredits > -5;
        },
        canDeductSteel() {
            return this.playerinput.payProduction.units.steel > 0;
        },
        canDeductTitanium() {
            return this.playerinput.payProduction.units.titanium > 0;
        },
        canDeductPlants() {
            return this.playerinput.payProduction.units.plants > 0;
        },
        canDeductEnergy() {
            return this.playerinput.payProduction.units.energy > 0;
        },
        canDeductHeat() {
            return this.playerinput.payProduction.units.heat > 0;
        },
        hasWarning() {
            return this.warning !== undefined;
        },
        delta(type, direction) {
            const expendableProductionQuantity = function (type, model) {
                switch (type) {
                    case 'megacredits':
                        return model.units.megacredits + 5;
                    case 'steel':
                        return model.units.steel;
                    case 'titanium':
                        return model.units.titanium;
                    case 'plants':
                        return model.units.plants;
                    case 'energy':
                        return model.units.energy;
                    case 'heat':
                        return model.units.heat;
                }
                return -1;
            };
            const current = this.$data[type];
            let newValue = current + direction;
            const lowestValue = (type === 'megacredit') ? -5 : 0;
            const expendableQuantity = expendableProductionQuantity(type, this.playerinput.payProduction);
            newValue = Math.min(Math.max(newValue, lowestValue), expendableQuantity);
            this.$data[type] = newValue;
        },
        saveData() {
            const total = (0, utils_1.sum)(Units_1.Units.values(this.units));
            if (total !== this.playerinput.payProduction.cost) {
                this.warning = `Pay a total of ${this.playerinput.payProduction.cost} production units`;
                return;
            }
            this.onsave({ type: 'productionToLose', units: this.units });
        },
    },
});
