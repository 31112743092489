"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const paths_1 = require("@/common/app/paths");
const HTTPResponseCode = require("@/client/utils/HTTPResponseCode");
const LogMessageType_1 = require("@/common/logs/LogMessageType");
const LogMessageDataType_1 = require("@/common/logs/LogMessageDataType");
const Card_vue_1 = require("@/client/components/card/Card.vue");
const TileType_1 = require("@/common/TileType");
const utils_1 = require("@/common/utils/utils");
const SoundManager_1 = require("@/client/utils/SoundManager");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const GlobalEvent_vue_1 = require("@/client/components/turmoil/GlobalEvent.vue");
const ClientGlobalEventManifest_1 = require("@/client/turmoil/ClientGlobalEventManifest");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const Log_1 = require("@/common/logs/Log");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const Colony_vue_1 = require("@/client/components/colonies/Colony.vue");
let logRequest;
const cardTypeToCss = {
    event: 'background-color-events',
    corporation: 'background-color-global-event',
    active: 'background-color-active',
    automated: 'background-color-automated',
    prelude: 'background-color-prelude',
    ceo: 'background-color-ceo',
    standard_project: 'background-color-standard-project',
    standard_action: 'background-color-standard-project',
    proxy: undefined,
};
const translatableMessageDataTypes = new Set([
    LogMessageDataType_1.LogMessageDataType.STRING,
    LogMessageDataType_1.LogMessageDataType.STANDARD_PROJECT,
    LogMessageDataType_1.LogMessageDataType.MILESTONE,
    LogMessageDataType_1.LogMessageDataType.AWARD,
    LogMessageDataType_1.LogMessageDataType.COLONY,
    LogMessageDataType_1.LogMessageDataType.PARTY,
    LogMessageDataType_1.LogMessageDataType.TILE_TYPE,
    LogMessageDataType_1.LogMessageDataType.GLOBAL_EVENT
]);
class ToggleSet {
    constructor() {
        this.elements = [];
    }
    toggle(item) {
        const index = this.elements.indexOf(item);
        if (index === -1) {
            this.elements.push(item);
        }
        else {
            this.elements.splice(index, 1);
        }
    }
    get length() {
        return this.elements.length;
    }
    clear() {
        return this.elements = [];
    }
}
exports.default = vue_1.default.extend({
    name: 'log-panel',
    props: {
        id: {
            type: String,
        },
        generation: {
            type: Number,
        },
        lastSoloGeneration: {
            type: Number,
        },
        players: {
            type: Array,
        },
        color: {
            type: String,
        },
        step: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            cardNames: new ToggleSet(),
            globalEventNames: new ToggleSet(),
            colonyNames: new ToggleSet(),
            messages: [],
            selectedGeneration: this.generation,
        };
    },
    components: {
        AppButton: AppButton_vue_1.default,
        Card: Card_vue_1.default,
        GlobalEvent: GlobalEvent_vue_1.default,
        Colony: Colony_vue_1.default,
    },
    methods: {
        scrollToEnd() {
            const scrollablePanel = document.getElementById('logpanel-scrollable');
            if (scrollablePanel !== null) {
                scrollablePanel.scrollTop = scrollablePanel.scrollHeight;
            }
        },
        cardToHtml(card, attrs) {
            const suffixFreeCardName = card.name.split(':')[0];
            const className = cardTypeToCss[card.type];
            if (className === undefined) {
                return suffixFreeCardName;
            }
            let tagHTML = '';
            if ((attrs === null || attrs === void 0 ? void 0 : attrs.tags) === true) {
                tagHTML = '&nbsp;' + (card.tags.map((tag) => `<div class="log-tag tag-${tag}"></div>`).join(' '));
            }
            let costHTML = '';
            if ((attrs === null || attrs === void 0 ? void 0 : attrs.cost) === true) {
                costHTML = `<span>&nbsp;<div class="log-resource-megacredits">${card.cost}</div></span>`;
            }
            return '<span class="log-card ' + className + '">' + this.$t(suffixFreeCardName) + tagHTML + costHTML + '</span>';
        },
        messageDataToHTML(data) {
            if (data.type === undefined || data.value === undefined) {
                return '';
            }
            switch (data.type) {
                case LogMessageDataType_1.LogMessageDataType.PLAYER:
                    for (const player of this.players) {
                        if (data.value === player.color) {
                            return '<span class="log-player player_bg_color_' + player.color + '">' + player.name + '</span>';
                        }
                    }
                    break;
                case LogMessageDataType_1.LogMessageDataType.CARD:
                    const cardName = data.value;
                    const card = (0, ClientCardManifest_1.getCard)(cardName);
                    if (card !== undefined) {
                        return this.cardToHtml(card, data.attrs);
                    }
                    else {
                        console.log(`Cannot render ${cardName}`);
                    }
                    break;
                case LogMessageDataType_1.LogMessageDataType.GLOBAL_EVENT:
                    const globalEventName = data.value;
                    return '<span class="log-card background-color-global-event">' + this.$t(globalEventName) + '</span>';
                case LogMessageDataType_1.LogMessageDataType.TILE_TYPE:
                    const tileType = Number(data.value);
                    return this.$t(TileType_1.tileTypeToString[tileType]);
                case LogMessageDataType_1.LogMessageDataType.COLONY:
                    const colonyName = data.value;
                    return '<span class="log-card background-color-colony">' + this.$t(colonyName) + '</span>';
                default:
                    if (translatableMessageDataTypes.has(data.type)) {
                        return this.$t(data.value);
                    }
            }
            return data.value;
        },
        safeMessage(message) {
            try {
                if (message === undefined) {
                    return 'undefined';
                }
                if (message.data === undefined) {
                    return `BUG: Unparseable message: ${message.message}`;
                }
                const data = message.data.map((datum) => {
                    return (datum === undefined) ?
                        'undefined' :
                        ('(' + datum.type + ') ' + datum.value);
                });
                return `BUG: Unparseable message: ${message.message}, (${data.join(', ')})`;
            }
            catch (err) {
                return `BUG: Unparseable message: ${message.message} ${String(err)}`;
            }
        },
        messageToHTML(message) {
            try {
                let logEntryBullet = '';
                if (message.type !== LogMessageType_1.LogMessageType.NEW_GENERATION) {
                    const when = new Date(message.timestamp).toLocaleString();
                    const icon = message.playerId === undefined ? '&#x1f551;' : '&#x1f4ac;';
                    logEntryBullet = `<span title="${when}">${icon}</span>`;
                }
                if (message.message !== undefined) {
                    message.message = this.$t(message.message);
                    return logEntryBullet + Log_1.Log.applyData(message, this.messageDataToHTML);
                }
            }
            catch (err) {
                console.log(err);
                return this.safeMessage(message);
            }
            return '';
        },
        messageClicked(message) {
            const datas = message.data;
            datas.forEach((data) => {
                if (data.value === undefined) {
                    return;
                }
                if (data.type === LogMessageDataType_1.LogMessageDataType.CARD) {
                    this.cardNames.toggle(data.value);
                }
                else if (data.type === LogMessageDataType_1.LogMessageDataType.GLOBAL_EVENT) {
                    this.globalEventNames.toggle(data.value);
                }
                else if (data.type === LogMessageDataType_1.LogMessageDataType.COLONY) {
                    this.colonyNames.toggle(data.value);
                }
            });
        },
        hideMe() {
            this.cardNames.clear();
            this.globalEventNames.clear();
            this.colonyNames.clear();
        },
        selectGeneration(gen) {
            if (gen !== this.selectedGeneration) {
                this.getLogsForGeneration(gen);
            }
            this.selectedGeneration = gen;
        },
        getLogsForGeneration(generation) {
            const messages = this.messages;
            if (logRequest !== undefined) {
                logRequest.abort();
                logRequest = undefined;
            }
            const xhr = new XMLHttpRequest();
            logRequest = xhr;
            xhr.open('GET', `${paths_1.paths.API_GAME_LOGS}?id=${this.id}&generation=${generation}`);
            xhr.onerror = () => {
                console.error('error updating messages, unable to reach server');
            };
            xhr.onload = () => {
                if (xhr.status === HTTPResponseCode.OK) {
                    messages.splice(0, messages.length);
                    messages.push(...xhr.response);
                    if ((0, PreferencesManager_1.getPreferences)().enable_sounds && window.location.search.includes('experimental=1')) {
                        SoundManager_1.SoundManager.newLog();
                    }
                    if (generation === this.generation) {
                        this.$nextTick(this.scrollToEnd);
                    }
                }
                else {
                    console.error(`error updating messages, response code ${xhr.status}`);
                }
            };
            xhr.responseType = 'json';
            xhr.send();
        },
        getClassesGenIndicator(gen) {
            const classes = ['log-gen-indicator'];
            if (gen === this.selectedGeneration) {
                classes.push('log-gen-indicator--selected');
            }
            return classes.join(' ');
        },
        getGenerationsRange() {
            const generations = [];
            for (let i = 1; i <= this.generation; i++) {
                generations.push(i);
            }
            return generations;
        },
        getTitleClasses() {
            const classes = ['log-title'];
            classes.push((0, utils_1.playerColorClass)(this.color.toLowerCase(), 'shadow'));
            return classes.join(' ');
        },
        lastGenerationClass() {
            return this.lastSoloGeneration === this.generation ? 'last-generation blink-animation' : '';
        },
        getGlobalEventModel(globalEventName) {
            return (0, ClientGlobalEventManifest_1.getGlobalEventModel)(globalEventName);
        },
        getColony(colonyName) {
            return {
                colonies: [],
                isActive: false,
                name: colonyName,
                trackPosition: 0,
                visitor: undefined,
            };
        },
        getResourcesOnCard(cardName) {
            for (const player of this.players) {
                const foundCard = player.tableau.find((card) => card.name === cardName);
                if (foundCard !== undefined)
                    return foundCard.resources;
            }
            return undefined;
        },
    },
    mounted() {
        this.getLogsForGeneration(this.generation);
    },
});
